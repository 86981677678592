import http from "@/utils/http";

/**
 * 获取学员列表
 */
export const getClubStudentList = data => {
	return http.get("/club/getClubStudentList", data);
};

/**
 * 新建编辑学员
 */
export const updateClubStudent = data => {
	return http.post("/club/updateClubStudent", data);
};

/**
 * 删除学员
 */
export const delClubStudent = data => {
	return http.post("/club/delClubStudent", data);
};

/**
 * 获取所有班级列表
 */
export const getAllClubClass = data => {
	return http.get("/club/getAllClubClass", data);
};

/**
 * 获取课程列表
 */
export const getClubCourseList = data => {
	return http.post("/club/getClubCourseList", data);
};

/**
 * 新建编辑课程
 */
export const updateClubCourse = data => {
	return http.post("/club/updateClubCourse", data);
};

/**
 * 获取草稿暂存
 */
export const getCourseTemp = data => {
	return http.get("/club/getCourseTemp", data);
};

/**
 * 保存草稿暂存
 */
export const updateCourseTemp = data => {
	return http.post("/club/updateCourseTemp", data);
};

/**
 * 根据课程Id获取课程详情
 */
export const getClubCourseById = data => {
	return http.get("/club/getClubCourseById", data);
};

/**
 * 取消课程
 */
export const cancelClubCourse = data => {
	return http.post("/club/cancelClubCourse", data);
};

/**
 * 获取考勤管理列表
 */
export const getAttendanceList = data => {
	return http.get("/club/getAttendanceList", data);
};

/**
 * 获取考勤详情
 */
export const getClassCourseCheckDetail = data => {
	return http.get("/club/getClassCourseCheckDetail", data);
};

/**
 * 获取班级考勤信息列表
 */
export const getClassCourseCheckList = data => {
	return http.get("/club/getClassCourseCheckList", data);
};

/**
 * 获取学员考勤信息列表
 */
export const getClassStudentCheckList = data => {
	return http.get("/club/getClassStudentCheckList", data);
};

/**
 * 获取消息通知列表
 */
export const getNoticeList = data => {
	return http.get("/club/getNoticeList", data);
};

/**
 * 获取消息通知详情
 */
export const getNoticeDetail = data => {
	return http.get("/club/getNoticeDetail", data);
};

/**
 * 根据课程id获取历史通知
 */
export const getNoticeListByCourseId = data => {
	return http.get("/club/getNoticeListByCourseId", data);
};

/**
 * 根据通知id生成通知二维码
 */
export const getNoticeQrCodeById = data => {
	return http.get("/club/getNoticeQrCodeById", data);
};

/**
 * 发送消息通知
 */
export const addCourseNotice = data => {
	return http.post("/club/addCourseNotice", data);
};

/**
 * 获取批量退班或换班
 */
export const batchQuitOrMoveClass = data => {
	return http.post("/club/batchQuitOrMoveClass", data);
};

/**
 * 班级名称联想
 */
export const classNameFind = data => {
	return http.get("/club/classNameFind", data);
};

/**
 * 教练名称联想
 */
export const teacherNameFind = data => {
	return http.get("/club/teacherNameFind", data);
};