<template>
  <div class="page page-box page-padding">
    <div class="go-class">
      <el-button @click="back" class="plain-button">
        <div class="img-center"> <img src="@/assets/return.png" alt="返回" class="add-img"> <span>返回</span></div>
      </el-button>
    </div>
    <div class="content-box" v-loading='loading'>
      <div class="content">
        <el-form :model="courseForm" :rules="courseRules" ref='courseForm' label-width="auto">
          <span class="h5">选择班级和课程时间</span>
          <el-form-item label="课程班级:" prop='ClassId'>
            <el-select class="select-box" @change='changeClassStatus()' filterable v-model="courseForm.ClassId" placeholder="请选择">
              <el-option v-for="item in classList" :key="item.Id" :label="item.Name" :value="item.Id">
                <div class="select-option-box">
                  <span class="option-left">{{ item.Name }}</span>
                  <span class="option-right">{{ item.SubOrgName }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程教练:" prop='Teachers'>
            <el-select class="select-box" @change='changeTeacherStatus' multiple filterable v-model="courseForm.Teachers" placeholder="请先选择班级">
              <el-option v-for="item in teachersList" :key="item.Id" :label="`${item.Name}(${item.TeacherType === 1 ? '主教练' : '助教'})`" :value="item.Id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否周期课程:" v-if="Id === '0'">
            <el-radio-group v-model="courseForm.Period">
              <el-radio :label="false">否</el-radio>
              <el-radio :label="true">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="Id === '0' && courseForm.Period" label="开始日期:" prop="StartDate" class="add-img">
            <el-date-picker class="date-box date-img" clear-icon v-model="courseForm.StartDate" :picker-options="startDatePickerOptions" type="date" placeholder="选择日期">
            </el-date-picker>
            <img src="@/assets/calendar.png" alt="" class="img-need">
          </el-form-item>
          <el-form-item v-else label="课程日期:" prop="CourseDate">
            <el-date-picker class="date-box" v-model="courseForm.CourseDate" :picker-options="courseDatePickerOptions" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期:" v-if="Id === '0' && courseForm.Period" prop="EndDate" class="add-img">
            <el-date-picker class="date-box" v-model="courseForm.EndDate" :picker-options="endDatePickerOptions" type="date" placeholder="选择日期">
            </el-date-picker>
            <img src="@/assets/calendar.png" alt="" class="img-need">
          </el-form-item>
          <el-form-item label="重复方式:" v-if="Id === '0' && courseForm.Period">
            <el-radio-group v-model="courseForm.RepetitionType">
              <el-radio :label="1">每周</el-radio>
              <el-radio :label="2">每天</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="Id === '0' && courseForm.Period && courseForm.RepetitionType === 1" label="星期选择:" prop="Weeks">
            <el-checkbox-group v-model="courseForm.Weeks" size="small">
              <el-checkbox-button v-for="item in weeks" :key='item.value' :label="item.value" class="check-week">{{ item.name }}</el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="课程时间:" prop='StartOrEndTime'>
            <el-row>
              <el-col :span='10'>
                <el-time-select class="date-box" placeholder="起始时间" v-model="courseForm.StartTime" :picker-options="{
                  start: '08:00',
                  step: '00:05',
                  end: '22:00',
                  maxTime: courseForm.EndTime,
                  minTime: minTime
                }">
                </el-time-select>
              </el-col>
              <el-col :span='2' :offset="1">至</el-col>
              <el-col :span='10' :offset="1">
                <el-time-select class="date-box" placeholder="结束时间" v-model="courseForm.EndTime" :picker-options="{
                  start: '08:00',
                  step: '00:05',
                  end: '21:00',
                  minTime: courseForm.StartTime || minTime
                }">
                </el-time-select>
              </el-col>
            </el-row>
          </el-form-item>
          <span class="h5">课程内容</span>
          <el-form-item label="课程主题:" prop="Theme">
            <el-input v-model="courseForm.Theme" placeholder="请输入课程主题" show-word-limit maxlength="20"></el-input>
          </el-form-item>
          <el-form-item>
            <p><i class="el-icon-warning"></i> 已安排课程时长： <span class="blush">{{Duration}}</span> 分钟 (创建的课程时长：{{ DurationTime }}分钟)</p>
          </el-form-item>
          <template v-for="(item,index) in trainModList">
            <div :key='index'>
              <el-form-item :label="`课程模块${index+1}`" required>
                <el-row>
                  <el-col :span='11'>
                    <el-select class="select-box" @change='changeTrainStatus(index)' filterable v-model="item.Id" placeholder="请先选择课程模块">
                      <el-option v-for="subItem in trainList" :key="subItem.Id" :label="subItem.Name" :value="subItem.Id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="2" class="Num-operation">
                    <img src="@/assets/reduce.png" alt="减" class="sum-img" @click="reduceNum(index)">
                  </el-col>
                  <el-col :span='4'>
                    <el-input v-model="item.Duration" placeholder="请输入" type="number" class="main-box no-number" id="val" />
                  </el-col>
                  <el-col :span="2" class="Num-operation">分钟</el-col>
                  <el-col :span="2" class="Num-operation">
                    <img src="@/assets/addNum.png" alt="加" class="sum-img" @click="addNum(index)">
                  </el-col>
                  <el-col :span='2' :offset="1"><i class="el-icon-delete" @click="deleteTrain(index)"></i></el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="模块内容" required>
                <el-input v-model="item.Remarks" :autosize="{ minRows: 6, maxRows: 5 }" type="textarea" placeholder="模块内容" show-word-limit maxlength="500" />
              </el-form-item>
              <br />
            </div>
          </template>
          <el-form-item label="">
            <el-row>
              <el-col :span='14'>
                <el-button type="success" @click="addTrainMod" class="add1-button">
                  <div class="img-center"> <img src="@/assets/add1.png" alt="添加" class="add-img"> <span>添加课程模块</span></div>
                </el-button>
              </el-col>
              <el-col :span='10'>
                <p> 已安排时长：{{ Duration }}分钟</p>
              </el-col>
            </el-row>
          </el-form-item>
          <span class="h5">课程要求</span>
          <el-form-item label="课程场地:" prop='SiteId'>
            <el-select class="select-box" @change='changeSiteStatus' filterable v-model="courseForm.SiteId" placeholder="请选择">
              <el-option v-for="item in siteList" :key="item.Id" :label="item.Name" :value="item.Id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程服装:" prop='clothName'>
            <el-input v-model="ClothingName" @focus="selectCloth()" placeholder="请选择课程服装" />
          </el-form-item>
          <el-form-item>
            <el-button @click="cancel" class="plain-button"> 取消 </el-button>
            <el-button v-preventReClick @click="onSubmit" class="discoloration"> 确定 </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="draftVisible" width="30%" :close-on-click-modal='false'>
      <span>是否保存当前内容？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="draftVisible = false" class="plain-button"> 取 消 </el-button>
        <el-button @click="cancelDraft" class="plain-button"> 不保存 </el-button>
        <el-button @click="sureDraft" class="discoloration"> 保 存 </el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择课程服装" :visible.sync="selectVisible" width="30%" :close-on-click-modal='false'>
      <el-radio-group v-model="courseForm.Clothings" class="radio-group">
        <el-radio class="radio" v-for="item in clothingList" :label="item.Id" :key="item.Id">
          <div class="radio-box">
            <span>{{`${item.Name}(${item.Color})`}}</span>
            <el-image class="image-box" :src="`${cosHttp}${item.ImageAddress}`" :preview-src-list="[`${cosHttp}${item.ImageAddress}`]" />
          </div>
        </el-radio>
      </el-radio-group>
      <div>
        <el-button @click="sureSelect()" class="right discoloration"> 选择 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAllClubClass, updateClubCourse, getClubCourseById, updateCourseTemp, getCourseTemp, addCourseNotice, getNoticeListByCourseId } from "@/service/educational";
import { getClubClothingList, getClubClassById, getClubSiteList } from '@/service/organizational';
import { getClubTrainModList } from '@/service/teaching';
import moment from 'moment';
export default {
  name: 'CreateCurriculum',
  data() {
    const validateClassId = (rule, value, callback) => {
      if (this.courseForm.ClassId) {
        return callback();
      }
      callback(new Error('请选择课程班级'));
    };

    const validateTeachers = (rule, value, callback) => {
      if (Array.isArray(this.courseForm.Teachers) && this.courseForm.Teachers.length > 0) {
        return callback();
      }
      callback(new Error('请选择课程教练'));
    };

    const validateStartDate = (rule, value, callback) => {
      if (this.courseForm.Period && this.courseForm.StartDate) {
        return callback();
      }
      callback(new Error('请选择开始时间'));
    };

    const validateEndDate = (rule, value, callback) => {
      if (this.courseForm.Period && this.courseForm.EndDate) {
        return callback();
      }
      callback(new Error('请选择结束时间'));
    };

    const validateCourseDate = (rule, value, callback) => {
      if (this.Id !== '0' && this.courseForm.CourseDate) {
        return callback();
      }
      if (this.Id === '0' && !this.courseForm.Period && this.courseForm.CourseDate) {
        return callback();
      }
      callback(new Error('请选择课程时间'));
    };

    const validateWeeks = (rule, value, callback) => {
      if (this.courseForm.Period && this.courseForm.RepetitionType === 1 && Array.isArray(this.courseForm.Weeks) && this.courseForm.Weeks.length > 0) {
        return callback();
      }
      callback(new Error('请选择重复星期'));
    };

    const validateStartOrEndTime = (rule, value, callback) => {
      if (this.courseForm.StartTime && this.courseForm.EndTime) {
        return callback();
      }
      callback(new Error('请选择开始和结束时间'));
    };

    const validateTheme = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入课程主题'));
      }
      callback();
    };

    const validateSiteId = (rule, value, callback) => {
      if (this.courseForm.SiteId) {
        return callback();
      }
      callback(new Error('请选择课程场地'));
    };

    const validateClothName = (rule, value, callback) => {
      if (!this.ClothingName || this.ClothingName.trim() === '') {
        return callback(new Error('请选择课程服装'));
      }
      callback();
    };

    return {
      courseForm: {
        ClassId: '',
        Teachers: [],
        Period: false,
        RepetitionType: 1,
        Weeks: [],
        EndTime: '',
        StartTime: '',
        EndDate: '',
        StartDate: '',
        Theme: '',
        SiteId: '',
        Clothings: '',
        CourseDate: ''
      },
      weeks: [
        {
          name: '一',
          value: 1
        },
        {
          name: '二',
          value: 2
        },
        {
          name: '三',
          value: 3
        },
        {
          name: '四',
          value: 4
        },
        {
          name: '五',
          value: 5
        },
        {
          name: '六',
          value: 6
        },
        {
          name: '日',
          value: 7
        },
      ],
      classList: [], // 所有班级列表
      siteList: [],  // 场地列表
      clothingList: [], // 服装列表
      teachersList: [], // 教练列表
      loading: false,
      courseRules: {
        ClassId: [{ required: true, validator: validateClassId, trigger: 'blur' }],
        Teachers: [{ required: true, validator: validateTeachers, trigger: 'blur' }],
        StartDate: [{ required: true, validator: validateStartDate, trigger: 'blur' }],
        CourseDate: [{ required: true, validator: validateCourseDate, trigger: 'blur' }],
        EndDate: [{ required: true, validator: validateEndDate, trigger: 'blur' }],
        Weeks: [{ required: true, validator: validateWeeks, trigger: 'blur' }],
        StartOrEndTime: [{ required: true, validator: validateStartOrEndTime, trigger: 'blur' }],
        Theme: [{ required: true, validator: validateTheme, trigger: 'blur' }],
        SiteId: [{ required: true, validator: validateSiteId, trigger: 'blur' }],
        clothName: [{ required: true, validator: validateClothName, trigger: 'blur' }],
      },
      startDatePickerOptions: {
        disabledDate: (time) => {
          if (this.Date.StartDate) {
            if (this.courseForm.EndDate) {
              return time.getTime() < this.Date.StartDate || time.getTime() < Date.now() - 8.64e7 || time.getTime() > this.courseForm.EndDate.getTime() || time.getTime() > this.Date.EndDate;
            }
            return time.getTime() < this.Date.StartDate || time.getTime() < Date.now() - 8.64e7 || time.getTime() > this.Date.EndDate;
          }
          else if (this.courseForm.EndDate) {
            return time.getTime() < Date.now() - 8.64e7 || time.getTime() > this.courseForm.EndDate.getTime();
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
      },
      courseDatePickerOptions: {
        disabledDate: (time) => {
          if (this.Date.StartDate && this.Date.EndDate) {
            return time.getTime() < this.Date.StartDate || time.getTime() < Date.now() - 8.64e7 || time.getTime() > this.Date.EndDate;
          }
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      endDatePickerOptions: {
        disabledDate: (time) => {
          if (this.Date.EndDate) {
            if (this.courseForm.StartDate) {
              return time.getTime() > this.Date.EndDate || time.getTime() < Date.now() - 8.64e7 || time.getTime() < this.Date.StartDate || time.getTime() < this.courseForm.StartDate.getTime();
            }
            return time.getTime() > this.Date.EndDate || time.getTime() < this.Date.StartDate || time.getTime() < Date.now() - 8.64e7;
          }
          else if (this.courseForm.StartDate) {
            return time.getTime() < Date.now() - 8.64e7 || time.getTime() < this.courseForm.StartDate.getTime();
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
      },
      trainModList: [{ Duration: 0, Id: '', Remarks: '' }], // 课程模块
      trainList: [],  // 所有课程模块
      Id: 0, // 课程Id
      editDetail: {}, // 编辑临时存储
      draftVisible: false, // 保存草稿弹窗
      Date: {},
      selectVisible: false, // 选择课程服装弹窗
      cosHttp: process.env.VUE_APP_COSHTTP,
      ClothingName: '',
      ModList: [],
      CourseId: '',
      // newNoticeList: []
    }
  },

  async created() {
    this.Id = this.$route.query.Id;
    this.loading = true;
    await this.getAllClubClass();
    await this.getClubSiteList();
    // await this.getNoticeListByCourseId();
    await this.getClubClothingList();
    await this.getClubTrainModList();
    this.Id !== '0' ? this.getClubCourseById() : this.getCourseTemp();
  },

  computed: {
    Duration() {
      let Duration = 0;
      Array.isArray(this.trainModList) && this.trainModList.forEach(item => {
        Duration += Number(item.Duration);
        if (item.Duration < 0) {
          this.$message.error('课程模块时长应大于0');
        }
      })
      if (Duration > this.DurationTime) {
        this.$message.error(`课程模块总安排时长不能大于${this.DurationTime}分钟`);
      }
      return Duration;
    },
    DurationTime() {
      if (this.courseForm.StartTime && this.courseForm.EndTime) {
        let hour = this.courseForm.EndTime.substring(0, 2) - this.courseForm.StartTime.substring(0, 2);
        let second = this.courseForm.EndTime.substring(3, 5) - this.courseForm.StartTime.substring(3, 5);
        if (hour * 60 + second < 30 || hour * 60 + second > 300) {
          this.$message.error('课程时间应大于等于30分钟或小于等于5小时');
        }
        return hour * 60 + second;
      } else {
        return 0;
      }
    },
    // 选择当天时的最小时间
    minTime() {
      let minTime = '';
      if (moment(this.courseForm.CourseDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD') || moment(this.courseForm.StartDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
        minTime = moment(new Date()).format('HH:mm');
      }
      return minTime
    }
  },

  methods: {

    /**
     * 获取草稿暂存
     */
    async getCourseTemp() {
      let res = await getCourseTemp();
      this.loading = false;
      if (res.CourseTemp) {
        this.courseForm.ClassId = JSON.parse(res.CourseTemp).ClassId;
        this.changeClassStatus(res.CourseTemp);
      };
    },

    /**
     * 根据课程Id获取历史通知
     */
    // async getNoticeListByCourseId() {
    //   let res = await getNoticeListByCourseId({ CourseId: this.Id })
    //   this.newNoticeList = res;
    // },

    /**
     * 根据Id获取详情
     */
    async getClubCourseById() {
      let res = await getClubCourseById({ Id: this.Id });
      this.loading = false;
      this.editDetail = res;
      this.courseForm.ClassId = res.ClassId;
      this.changeClassStatus('edit');
    },

    /**
     * 获取课程模块列表
     */
    async getClubTrainModList() {
      const res = await getClubTrainModList({ PageSize: 1000 });
      this.trainList = res.DataList;
    },

    /**
     * 获取所有班级列表
     */
    async getAllClubClass() {
      let res = await getAllClubClass();
      this.classList = res.filter(item => item.Type !== 2 || item.EndDate * 1000 > Date.now());
    },

    /**
     * 根据班级获取教练列表
     */
    async getClubClassById() {
      const res = await getClubClassById({ Id: this.courseForm.ClassId });
      this.teachersList = res.Teachers;
    },

    /**
     * 获取所有场地列表
     */
    async getClubSiteList() {
      const res = await getClubSiteList({ PageSize: 1000 });
      this.siteList = res.DataList;
    },

    /**
     * 获取所有服装列表
     */
    async getClubClothingList() {
      const res = await getClubClothingList({ PageSize: 1000 });
      this.clothingList = res.DataList;
    },

    /**
     * 选择所属班级时改变验证状态
     */
    changeClassStatus(edit) {
      this.getClubClassById();
      this.courseForm.Teachers = [];
      let index = this.classList.findIndex(item => item.Id === this.courseForm.ClassId && item.Type === 2);
      if (index != -1) { // 判断选择的班级是否是短期班
        this.Date = {
          EndDate: this.classList[index].EndDate * 1000,
          StartDate: this.classList[index].StartDate * 1000,
        }
        this.courseForm.StartDate = '';
        this.courseForm.EndDate = '';
      } else {
        this.Date = {
          EndDate: 0,
          StartDate: 0,
        }
      }
      if (edit === 'edit') {
        this.courseForm.Teachers = Array.isArray(this.editDetail.Teachers) && this.editDetail.Teachers.map(item => item.Id) || [];
        this.courseForm.CourseDate = this.editDetail.StartTime && new Date(moment(this.editDetail.StartTime * 1000).format('YYYY-MM-DD 00:00:00')) || '';
        this.courseForm.StartTime = this.editDetail.StartTime && moment(this.editDetail.StartTime * 1000).format('HH:mm') || '';
        this.courseForm.EndTime = this.editDetail.EndTime && moment(this.editDetail.EndTime * 1000).format('HH:mm') || '';
        this.courseForm.Theme = this.editDetail.Theme;
        this.trainModList = [...this.editDetail.TrainMods];
        this.courseForm.SiteId = this.editDetail.SiteId !== '0' ? this.editDetail.SiteId : '';
        this.courseForm.Clothings = Array.isArray(this.editDetail.Clothings) && this.editDetail.Clothings.length > 0 && this.editDetail.Clothings[0].Id;
      } else if (edit) {
        this.courseForm = JSON.parse(edit);
        this.trainModList = [...this.courseForm.TrainMods];
        this.courseForm.CourseDate = this.courseForm.CourseDate && new Date(moment(this.courseForm.CourseDate).format('YYYY-MM-DD 00:00:00')) || '';
        this.courseForm.StartDate = this.courseForm.StartDate && new Date(moment(this.courseForm.StartDate).format('YYYY-MM-DD 00:00:00')) || '';
        this.courseForm.EndDate = this.courseForm.EndDate && new Date(moment(this.courseForm.EndDate).format('YYYY-MM-DD 00:00:00')) || '';
        this.courseForm.Clothings = Array.isArray(this.courseForm.Clothings) && this.courseForm.Clothings.length > 0 && this.courseForm.Clothings[0];
      }
      this.sureSelect('edit');
      this.$refs.courseForm.validateField('ClassId');

    },

    /**
     * 选择教练时改变验证状态
     */
    changeTeacherStatus() {
      this.$refs.courseForm.validateField('Teachers');
    },

    /**
     * 选择场地时改变验证状态
     */
    changeSiteStatus() {
      this.$refs.courseForm.validateField('SiteId');
    },

    /**
     * 选择服装时改变验证状态
     */
    changeClothingsStatus() {
      this.$refs.courseForm.validateField('Clothings');
    },

    /**
     * 选择课程模块时改变验证状态
    */
    changeTrainStatus(index) {
      this.trainList.forEach(item => {
        if (item.Id === this.trainModList[index].Id) {
          this.trainModList[index].Duration = item.Duration;
          this.trainModList[index].Remarks = item.Content;
        }
      });
      this.$forceUpdate();
    },

    /**
     * 添加课程模块
     */
    addTrainMod() {
      let obj = {
        Duration: 0,
        Id: '',
        Remarks: ''
      };
      this.trainModList.push(obj);
    },

    /**
    * 删除课程模块
    */
    deleteTrain(index) {
      if (Array.isArray(this.trainModList) && this.trainModList.length === 1) {
        this.$message.error('至少保留一个课程模块');
        return;
      }
      Array.isArray(this.trainModList) && this.trainModList.splice(index, 1);
    },

    onSubmit() {
      let arr = Array.isArray(this.trainModList) && this.trainModList.filter(item => item.Id);
      let flag = false;
      let TrainMods = arr.map(item => {
        if (Number(item.Duration < 0)) {
          flag = true;
        }
        return {
          ...item,
          Duration: Number(item.Duration)
        };
      })

      this.ModList = Array.isArray(this.trainModList) && this.trainModList.map(item => item.Remarks);
      let flags = true;
      this.ModList.forEach(item => {
        if (!item || item.trim() === '') {
          flags = false;
        }
      })
      if (!flags) {
        this.$message.error('请填写模块内容')
        return;
      }

      if (flag) {
        this.$message.error('课程模块时长应大于0');
        return;
      }
      if (TrainMods.length === 0) {
        this.$message.error('请选择至少一个课程模块');
        return;
      }
      if (this.DurationTime < 30 || this.DurationTime > 300) {
        this.$message.error('课程时间应大于等于30分钟或小于等于5小时');
        return;
      }
      if (this.Duration > this.DurationTime) {
        this.$message.error(`课程模块总安排时长应小于等于${this.DurationTime}分钟`);
        return;
      }

      this.$refs.courseForm.validate(async (valid) => {
        if (!valid) return;
        let flag = false;
        try {
          let res = await this.$confirm('确定要提交吗', '警告', {
            distinguishCancelAndClose: true,
            confirmButtonText: '提 交',
            cancelButtonText: '取 消',
            type: 'warning',
            center: true,
          })

          if (res !== 'confirm') return;
          if (this.Id !== '0') {
            await updateClubCourse({
              TrainMods,
              StartTime: this.courseForm.StartTime,
              EndTime: this.courseForm.EndTime,
              ClassId: this.courseForm.ClassId,
              Teachers: this.courseForm.Teachers,
              Theme: this.courseForm.Theme,
              SiteId: this.courseForm.SiteId,
              Id: this.Id,
              Clothings: [this.courseForm.Clothings],
              CourseDate: this.courseForm.CourseDate && this.courseForm.CourseDate.getTime() / 1000 || 0,
              Status: 0
            });
            this.$message.success('课程编辑成功');
          } else {
            let res = await updateClubCourse({
              ...this.courseForm,
              TrainMods,
              Clothings: [this.courseForm.Clothings],
              StartDate: this.courseForm.StartDate && this.courseForm.StartDate.getTime() / 1000 || 0,
              EndDate: this.courseForm.EndDate && this.courseForm.EndDate.getTime() / 1000 || 0,
              CourseDate: this.courseForm.CourseDate && this.courseForm.CourseDate.getTime() / 1000 || 0,
              Status: 0,
            });
            this.CourseId = res[0].Id;
            this.$message.success('课程新建成功');
          }
        } catch (err) {
          if (err) {
            flag = true;
          }
        };
        if (flag) return;
        try {
          let req = await this.$confirm(`${this.courseForm.Period === true && this.Id === '0' ? '多个' : ''}课程${this.Id !== '0' ? '内容发生修改' : '已创建'}，是否${this.courseForm.Period === true && this.Id === '0' ? '通知家长最近一节课程信息' : ''}发布课程${this.Id !== '0' ? '修改' : ''}通知到家长？`, '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '通知家长',
            cancelButtonText: '取 消',
            type: 'warning',
            center: true,
          })
          if (req !== 'confirm') {
            return;
          }
          await addCourseNotice({
            ClubClassId: this.courseForm.ClassId,
            ClubCourseId: this.editDetail.Id || this.CourseId,
            Type: this.editDetail.NoticeId !== '0' ? this.Id !== '0' ? 2 : 1 : 1
          });
          this.$message.success('发送通知成功');
          // this.$router.push({
          //   path: '/educational/curriculum'
          // });
          this.$router.go(-1);
        } catch (err) {
          this.$router.go(-1);
        }
      })
    },

    /**
     * 返回上一级页面
     */
    back() {
      if (this.Id === '0') {
        this.$refs.courseForm.validate(async (valid, obj) => {
          // 判断是否有项目选择过，都未选直接返回
          if (!valid && (this.courseForm.Period ? Object.keys(obj).length === 9 : Object.keys(obj).length === 7)) {
            this.$router.go(-1);
            return
          }
          this.draftVisible = true
        })
      } else {
        this.$router.go(-1);
      }
    },

    /**
     * 不保存草稿
    */
    async cancelDraft() {
      this.draftVisible = false;
      await updateCourseTemp({
        CourseTemp: ""
      });
      this.$router.go(-1);
    },

    /**
     * 保存草稿
    */
    async sureDraft() {
      let TrainMods = Array.isArray(this.trainModList) && this.trainModList.filter(item => item.Id);
      await updateCourseTemp({
        CourseTemp: JSON.stringify({
          ...this.courseForm,
          TrainMods,
          Clothings: [this.courseForm.Clothings],
          StartDate: this.courseForm.StartDate && moment(this.courseForm.StartDate).format('YYYY-MM-DD') || "",
          EndDate: this.courseForm.EndDate && moment(this.courseForm.EndDate).format('YYYY-MM-DD') || "",
          CourseDate: this.courseForm.CourseDate && moment(this.courseForm.CourseDate).format('YYYY-MM-DD') || "",
        })
      });
      this.draftVisible = false;
      this.$message.success('草稿保存成功');
      this.$router.go(-1);
    },

    /**
     * 取消新建编辑
     */
    async cancel() {

      if (this.Id === '0') {
        this.$refs.courseForm.validate(async (valid, obj) => {
          // 判断是否有项目选择过，都未选直接返回
          if (!valid && (this.courseForm.Period ? Object.keys(obj).length === 9 : Object.keys(obj).length === 7)) {
            this.$router.go(-1);
            return
          }
          this.draftVisible = true
        })
      } else {
        try {
          let res = await this.$confirm('是否确定退出编辑课程？', '警告', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true,
          })
          if (res !== 'confirm') return;
          this.$router.go(-1);
        } catch (err) { };
      }
    },

    /**
     * 选择服装
     */
    selectCloth() {
      this.selectVisible = true;
    },

    /**
     * 点击选择
     */
    sureSelect(edit) {
      if (!this.courseForm.Clothings) {
        !edit && this.$message.error('请选择要选择的服装');
        return
      }
      this.selectVisible = false;
      let arr = Array.isArray(this.clothingList) && this.clothingList.filter(item => {
        return item.Id === this.courseForm.Clothings
      });
      this.ClothingName = arr.length > 0 && `${arr[0].Name}` || '';
      this.$refs.courseForm.validateField('clothName');
    },

    /**
     * 点击-减
     */
    reduceNum(index) {
      this.trainModList[index].Duration -= 1;
    },

    /**
     * 点击-加
     */
    addNum(index) {
      this.trainModList[index].Duration = Number(this.trainModList[index].Duration) + 1;
    },
  }
}
</script>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 15px;
}
.content-box {
  background: #ffffff;
  margin-top: 16px;
  padding: 10px;
  overflow: auto;
  .content {
    width: 600px;
    margin: 10px auto;
  }
}

.date-box {
  width: 100%;
}
.blush {
  color: #fe3a30;
}

.radio-group {
  height: 400px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.radio {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  /deep/ .el-radio__label {
    flex: 1;
  }
  .radio-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.radio:last-child {
  margin-right: 30px;
}
.right {
  margin-left: 80%;
}

// 统计字数调整位置
/deep/.el-textarea .el-input__count {
  line-height: 20px;
  bottom: -20px !important;
  right: 0px !important;
}

/deep/.el-checkbox-button--small .el-checkbox-button__inner {
  width: 46px;
  height: 32px;
  margin-right: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
/deep/.el-checkbox-button--small .el-checkbox-button__inner:hover {
  color: #8fcc80;
}
/deep/.el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #8fcc80;
  border-color: #8fcc80;
  box-shadow: -1px 0 0 0 #8fcc80;
  color: #ffffff;
}
/deep/.el-checkbox-button--small .el-checkbox-button__inner {
  font-size: 14px;
  font-weight: 400;
  color: #6a757e;
}
.add1-button {
  color: #8fcc80;
  width: 130px;
  height: 32px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #8fcc80;
}
.h5 {
  font-size: 14px;
  font-weight: bold;
  color: #21293a;
  line-height: 40px;
}
.el-form-item {
  padding-left: 0px !important;
}
.add-img {
  position: relative;
}
.img-need {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 12px;
}
// 隐藏icon
/deep/.el-input__icon {
  display: none;
}

.Num-operation {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  .sum-img {
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

// .el-select {
//   /deep/.el-select__tags {
//     // 解决多个tag卡片溢出隐藏问题
//     overflow: hidden;
//     display: -webkit-box;
//     -webkit-line-clamp: 1;
//     -webkit-box-orient: vertical;
//     .el-tag {
//       // 注意这个inline-block 对卡片溢出隐藏很重要
//       display: inline-block;
//       // 解决单个tag文字过长溢出隐藏问题
//       .el-select__tags-text {
//         display: inline-block;
//         max-width: 200px;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//       }
//       .el-icon-close {
//         top: -8px;
//         right: -4px;
//       }
//     }
//   }
// }
</style>