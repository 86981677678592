import http from "@/utils/http";

/**
 * 获取课程大纲列表
 */
export function getClubOutlineList(data) {
  return http.get("/club/getClubOutlineList", data);
};

/**
 * 获取新增编辑课程大纲
 */
export function addOrUpdateClubOutline(data) {
  return http.post("/club/addOrUpdateClubOutline", data);
};

/**
 * 删除课程大纲
 */
export function delClubOutline(data) {
  return http.post("/club/delClubOutline", data);
};

/**
 * 获取训练模块列表
 */
export function getClubTrainModList(data) {
  return http.get("/club/getClubTrainModList", data);
};

/**
 * 获取新增编辑训练模块
 */
export function addOrUpdateClubTrainMod(data) {
  return http.post("/club/addOrUpdateClubTrainMod", data);
};

/**
 * 删除训练模块
 */
export function delClubTrainMod(data) {
  return http.post("/club/delClubTrainMod", data);
};